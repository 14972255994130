<template>
    <div class="card-solicitud" @click="$emit('mostrar',cartelera.id)">
        <!-- solo se muestra el (div.seleccionado) en la "vista verAnunciosVivienda" cuando es seleccionado -->
        <div class="seleccionado d-none"></div>
        <div class="container-img">
            <img v-if="cartelera.imagen" :src="cartelera.imagen" alt="" class="obj-cover h-100 w-100">
            <img v-else src="/img/no-imagen/noimagenanuncio.svg" alt="" class="obj-cover h-100 w-100">
        </div>
        <div class="row mx-0 container-info pb-1">
            <p class="col-12 f-15 my-1">{{cartelera.titulo}}</p>
            <!-- El valor se muestra segun el tipo de anuncio -->
            <p class="col-12 f-17 my-1">{{ convertMoney(cartelera.dinero) }}</p>
            <div class="col-auto my-1">
                <div class="categoria d-middle br-20 px-2 f-12" :style="`background: ${_.get(cartelera.categoria, 'color')}`">
                    <i class="icon-shop-outline mr-2 op-05" />
                    <p class="tres-puntos op-05">{{_.get(cartelera.categoria, 'nombre')}}</p>
                </div>
            </div>
            <!-- solo se muestra el estado en ciertas vistas los bg son: 
            bg-rechazado, bg-finalizado, bg-pendiente, bg-activo -->
            <div class="col-auto my-1">
                <div class="categoria d-middle br-20 px-2 f-12 bg-activo">
                    <div v-if="cartelera.estado==1" class="categoria d-middle br-20 px-2 f-12 bg-activo">
                              <p class="tres-puntos op-05">Pendiente</p>
                          </div>
                          <div v-if="cartelera.estado==2" class="categoria d-middle br-20 px-2 f-12 bg-activo">
                              <p class="tres-puntos op-05">Aprobado</p>
                          </div>
                          <div v-if="cartelera.estado==3" class="categoria d-middle br-20 px-2 f-12 bg-activo">
                              <p class="tres-puntos op-05">Rechazado</p>
                          </div>
                          <div v-if="cartelera.estado==4" class="categoria d-middle br-20 px-2 f-12 bg-activo">
                              <p class="tres-puntos op-05">Cancelado Admin</p>
                          </div>
                          <div v-if="cartelera.estado==5" class="categoria d-middle br-20 px-2 f-12 bg-activo">
                              <p class="tres-puntos op-05">Cancelado Cliente</p>
                          </div>
                          <div v-if="cartelera.estado==6" class="categoria d-middle br-20 px-2 f-12 bg-activo">
                              <p class="tres-puntos op-05">Vencido</p>
                          </div>
                </div>
            </div>
            <div class="col-12 my-1">
                <div class="d-flex">
                    <div class="tipo-user rounded-circle d-middle-center my-auto" style="background:#80DCFF">
                        <i class="icon-account-outline op-05 f-20" />
                    </div>
                    <div class="tres-puntos ml-2">
                        <p class="f-15 f-500">{{_.get(cartelera.usuario, 'nombre')}}</p>
                        <p class="f-12 tres-puntos">{{ _.get(cartelera, 'vivienda') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
      cartelera: Object
    },
    data(){
        return{
            
        }
    }
}
</script>
<style lang="scss" scoped>
.card-solicitud{
        max-width: 304px;
        border-radius: 8px;
        position: relative;
        cursor: pointer;
        position: relative;
        .seleccionado{
            width: 8px;
            background: var(--color-general);
            position: absolute;
            height: 98%;
            border-radius: 20px;
            top: 0px;
            left: -11px;
            z-index: 1;
        }
        .container-img{
            height: 182px;
            overflow: hidden;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        .container-info{
            // min-height: 141px;
            // height: 141px;
            border-radius: 8px;
            border:1px solid #DBDBDB;
            position: relative;
            top: -8px;
            background: white;
            .categoria{
                min-height: 22px;
            }
            .tipo-user{
                min-width: 32px;
                max-width: 32px;
                max-height: 32px;
                min-height: 32px;
            }
        }
    }
</style>